import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import AntTooltip from '../../global/AntTooltip';
import ApplicationCard from './ApplicationCard';
import { Item } from '../../../../types/item';
import {
  StructuredApplication,
  ApplicationGroups,
  LinkedApplications,
  Qualifier,
  ApplicationNote,
  AppOrder,
} from '../../../../types/application';
import { Analysis } from '../../../../types/analyses';
import { AnalysisType, Vehicle } from '../../../../types/resources';

type ApplicationCardGroupsProps = {
  selectedItem: Item;
  bundle: boolean;
  isReceiver: boolean;
  isManufacturer: boolean;
  applications: StructuredApplication[];
  applicationOrder: AppOrder[];
  analyses: (Analysis & AnalysisType)[];
  analysisGroups: ApplicationGroups;
  baseItemApplications: LinkedApplications[];
  oeItemApplications: LinkedApplications[];
  resources: Vehicle;
  defaultQualifiers: Qualifier[];
  defaultNotes: ApplicationNote[];
  selectConfig: (id: number, config?: string, defaultValue?: boolean) => void;
  deleteApplication: (id: number) => void;
  unlinkItem: (itemId: number) => void;
} & WithTranslation;

enum ApplicationGroupType {
  'STANDARD',
  'INCONSISTENT',
}

class ApplicationCardGroups extends React.PureComponent<ApplicationCardGroupsProps> {
  getColor = (alertType: number) => {
    if (alertType === 1) return 'blue';
    if (alertType === 2) return 'orange';
    if (alertType === 3) return 'red';
    return '';
  };

  receiverApplications = () => {
    const { baseItemApplications, oeItemApplications, applications } = this.props;

    // for receiver we don't want the information of linked applications
    return (
      <div className="application__linked-item-group applications__wrapper">
        <div className="applications__grid">
          {[...baseItemApplications, ...oeItemApplications].map(itemApplications =>
            itemApplications.applications.map(application => this.application(application, true))
          )}
          {applications.map(application => this.application(application, true))}
        </div>
      </div>
    );
  };

  linkedApplications = () => {
    const { t, baseItemApplications, oeItemApplications } = this.props;

    return [...baseItemApplications, ...oeItemApplications].map(itemApplications => {
      const oeItem = !!oeItemApplications.find(
        oeItemApplication => itemApplications.item_id === oeItemApplication.item_id
      );
      const whiteLabelCode = itemApplications.default_whitelabel_brand_code;

      return (
        <div
          className="application__linked-item-group applications__wrapper blue-bg"
          key={itemApplications.item_id}
        >
          <div className="application__linked-item-header flex items-center">
            {`${oeItem ? t('application:receiveFromOE') : t('application:receiveFromInherited')} `}
            <Tag>
              {!oeItem &&
                `${
                  whiteLabelCode
                    ? `${whiteLabelCode} (${itemApplications.brand_code}) | `
                    : `${itemApplications.brand_code} | `
                }`}
              {itemApplications.part_number}
            </Tag>
            {!oeItem && (
              <Button
                className="application__unlink-button"
                onClick={() => this.props.unlinkItem(itemApplications.item_id)}
                type="link"
                size="small"
              >
                {t('application:unlinkItem')}
              </Button>
            )}
            <div className="flex-1 flex flex-row-reverse">
              <AntTooltip title={t('application:info.inheritedPosition')}>
                <Tag className="application__position-hint" color="blue">
                  <InfoCircleOutlined />
                  <span className="ml-1">{t('application:info.positionHint')}</span>
                </Tag>
              </AntTooltip>
            </div>
          </div>

          <div className="applications__grid">
            {itemApplications.applications.map(application =>
              this.application(application, true, true)
            )}
          </div>
        </div>
      );
    });
  };

  application = (
    application: StructuredApplication,
    noButtons?: boolean,
    inheritedApplication?: boolean,
    index?: number
  ) => {
    const { analyses, selectedItem, resources } = this.props;
    const defaultPosition = selectedItem.default_position_id
      ? resources.positions.find(value => selectedItem.default_position_id === value.id)
      : undefined;
    const parentDefaultPosition =
      inheritedApplication && application.item_position_ids
        ? resources.positions.find(value => application.item_position_ids[0] === value.id)
        : undefined;

    return (
      <ApplicationCard
        key={application.id}
        application={application}
        defaultQty={selectedItem.default_application_qty}
        defaultMfr={selectedItem.default_mfr_label || null}
        defaultPosition={defaultPosition}
        defaultPartType={selectedItem.category}
        parentDefaultPosition={parentDefaultPosition}
        defaultQualifiers={this.props.defaultQualifiers}
        defaultNotes={this.props.defaultNotes}
        analyses={analyses.filter(analysis => analysis.reference_id === application.id)}
        deleteApplication={this.props.deleteApplication}
        selectConfig={this.props.selectConfig}
        noButtons={noButtons}
        inheritedApplication={inheritedApplication}
        index={index}
      />
    );
  };

  getGrouppedApplication = (key: string) => {
    const { analysisGroups, applications } = this.props;
    const applicationIds = analysisGroups[key] || [];
    const groupApplications = applications.filter(application =>
      applicationIds.includes(application.id)
    );
    const groupAnalysis = this.props.analyses.find(
      analysis =>
        analysis.reference_id === groupApplications[0].id &&
        typeof analysis.details === 'object' &&
        analysis.details !== null &&
        analysis.details.key === key
    );
    return { groupApplications, groupAnalysis };
  };

  applications = () => {
    const { applications, bundle, applicationOrder } = this.props;
    const handledGroupKeys: string[] = [];

    const sortedApplication: {
      type: ApplicationGroupType;
      applications: StructuredApplication[];
      groupAnalysis?: Analysis & AnalysisType;
    }[] = [];

    applications.map(application => {
      // check if application has group analysis
      const applicationAnalyses = this.props.analyses.filter(
        analysis =>
          analysis.reference_id === application.id &&
          analysis.details &&
          typeof analysis.details === 'object' &&
          analysis.details.key
      );
      if (applicationAnalyses.length > 0) {
        const key =
          (typeof applicationAnalyses[0].details === 'object' &&
            applicationAnalyses[0].details!.key) ||
          '';
        if (!handledGroupKeys.includes(key)) {
          handledGroupKeys.push(key);
          const { groupAnalysis, groupApplications } = this.getGrouppedApplication(key);
          sortedApplication.push({
            type: ApplicationGroupType.INCONSISTENT,
            applications: groupApplications,
            groupAnalysis,
          });
        }
        return null;
      }
      const lastEntry = sortedApplication[sortedApplication.length - 1];
      if (lastEntry && lastEntry.type === ApplicationGroupType.STANDARD) {
        lastEntry.applications = [...lastEntry.applications, application];
      } else {
        sortedApplication.push({
          type: ApplicationGroupType.STANDARD,
          applications: [application],
        });
      }
    });
    return sortedApplication.map((application, index) => {
      if (application.type === ApplicationGroupType.STANDARD) {
        return (
          <div key={index} className="applications__wrapper">
            <div className="applications__grid">
              {application.applications.map(app => {
                const applicationIndex = bundle
                  ? applicationOrder.findIndex(a => a.item_application_id === app.id)
                  : undefined;
                return this.application(app, this.props.isReceiver, false, applicationIndex);
              })}
            </div>
          </div>
        );
      }
      if (application.type === ApplicationGroupType.INCONSISTENT) {
        const { groupAnalysis } = application;
        const color = groupAnalysis && this.getColor(groupAnalysis.alert_type_id);
        return (
          <div key={index} className={`applications__wrapper ${color}-bg`}>
            <Tag color={color} style={{ marginBottom: 10 }}>
              <span>{groupAnalysis && groupAnalysis.name}</span>
              <span style={{ marginLeft: 5 }}>
                <AntTooltip title={groupAnalysis && groupAnalysis.description}>
                  <InfoCircleOutlined />
                </AntTooltip>
              </span>
            </Tag>
            <div className="applications__grid">
              {application.applications.map(app => {
                const applicationIndex = bundle
                  ? applicationOrder.findIndex(a => a.item_application_id === app.id)
                  : undefined;
                return this.application(app, this.props.isReceiver, false, applicationIndex);
              })}
            </div>
          </div>
        );
      }
    });
  };

  render() {
    const { isManufacturer, isReceiver } = this.props;
    return (
      <div>
        {isManufacturer && this.linkedApplications()}
        {isManufacturer && this.applications()}
        {isReceiver && this.receiverApplications()}
      </div>
    );
  }
}

export default withTranslation()(ApplicationCardGroups);
