import React from 'react';
import { connect } from 'react-redux';
import { Layout, notification, message } from 'antd';
import { Outlet } from 'react-router-dom';
import { fetchInitalResources } from '../actions/resources';
import RefreshPage from '../components/RefreshPage';
import ModalRoot from '../components/global/Modals/ModalRoot';
import parentBrandActions from '../actions/parent/brands';
import { logout, fetchRoles, fetchPermissions } from '../actions/user';
import { fetchScripts } from '../actions/distribution_apisettings';
import * as actions from '../actions/app/global';
import receiverActions from '../actions/receiver/index.ts';

import SideBarLeft from '../components/layout/SideBarLeft';
import SynchronisationManager from '../containers/synchronisation/SynchronisationManager';
import { hasPermission } from '../utils/Permissions';
import { isManufacturer, isReceiver } from '../utils/UserUtils.ts';
import ChannelPusher from '../containers/channelPusher/ChannelPusher';
import Importer from '../containers/importer/Importer';
import Exporter from '../containers/exporter/Exporter';
import ErrorBoundary from '../components/global/ErrorBoundary';

const { Content } = Layout;

export class MainPage extends React.Component {
  componentDidMount() {
    const { user } = this.props;
    // inital fetch for app
    this.props.dispatch(fetchInitalResources());
    this.props.dispatch(fetchRoles());
    this.props.dispatch(fetchPermissions());
    this.props.dispatch(fetchScripts());
    this.checkVersionUpdate();

    const hasBrandAccess = hasPermission(user, 'has_brand_access');
    const hasChannelAccess = hasPermission(user, 'has_channel_access');
    if (hasBrandAccess) {
      this.props.dispatch(parentBrandActions.fetchBrands(user.parent_id));
    }
    if (hasChannelAccess && isManufacturer(this.props.user)) {
      this.props.dispatch(receiverActions.fetchReceiverRequestCount());
    } else if (isReceiver(this.props.user)) {
      this.props.dispatch(receiverActions.fetchBrandRequestCount());
    }
  }

  checkVersionUpdate = () => {
    const scripts = document.body.getElementsByTagName('script');
    const script = Array.from(scripts).find(script => script.src.includes('main'));
    if (script) {
      const scriptName = script.src.split('/').pop();
      this.props.dispatch(actions.setCurrentVersion(scriptName));
      this.props.dispatch(actions.checkVersionUpdate());

      setInterval(() => {
        // then check for newer version
        if (!this.props.newVersion) {
          this.props.dispatch(actions.checkVersionUpdate());
        }
      }, 300000); // 5min interval
    }
  };

  logout = async () => {
    notification.destroy();
    message.destroy();
    await this.props.dispatch(logout());
    if (globalThis.apiRoot) globalThis.apiRoot = undefined;
  };

  render() {
    return (
      <React.Fragment>
        <Layout className="layout">
          <SideBarLeft logout={this.logout} />
          <Content className="main-page-content">
            <RefreshPage newVersion={this.props.newVersion} />
            <Outlet />
          </Content>
        </Layout>
        <ErrorBoundary noMessage>
          <Importer />
          <Exporter />
          <SynchronisationManager />
          <ChannelPusher />
        </ErrorBoundary>
        <ModalRoot />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  newVersion: state.app.global.newVersion,
  user: state.user.user,
});

export default connect(mapStateToProps)(MainPage);
