import React from 'react';
import { Button, Input, Spin } from 'antd';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as utils from '../../../utils/Utils';
import VcdbAddConfigPopover from './VcdbAddConfigPopover';
import VcdbAddTypeConfigPopover from './VcdbAddTypeConfigPopover';
import VcdbAddModelPopover from './VcdbAddModelPopover';

type ApplicationColumnProps = {
  title: string | React.ReactNode;
  subconfigName?: string;
  scrollToFirstElement?: boolean;
  rows: any[];
  selected: number[] | null;
  cmdKeyMultiselect?: boolean;
  multiselect?: boolean;
  divider?: boolean;
  noRank3?: boolean;
  getRecommendations: boolean;
  fetching?: boolean;
  addCustomValue?: (
    configName: string,
    value: string,
    type?: string,
    group?: string
  ) => Promise<any>;
  deleteCustomValue?: (configName: string, id: number) => Promise<any>;
  filter?: (keywords: string) => void;
  mapConfigToApplication?: (subconfigName: string, rowId: number) => void;
  submit?: (rowId: number | number[], addValues: any, save?: boolean) => void;
  handleClearSelection?: () => void;
} & WithTranslation;

type ApplicationColumnState = {
  data: any[];
  scrolledToFirstElement: boolean;
  scrollToIndex?: number;
  keywords: string;
};

class ApplicationColumn extends React.Component<ApplicationColumnProps, ApplicationColumnState> {
  private filterInput = React.createRef<any>();

  private listRef = React.createRef<List>();

  cache: CellMeasurerCache;

  selectedStart: number | null = null;

  lastSelected: number | null = null;

  constructor(props: ApplicationColumnProps) {
    super(props);
    this.cache = new CellMeasurerCache({ defaultHeight: 30, fixedWidth: true });

    this.state = {
      data: props.rows,
      scrolledToFirstElement: false,
      scrollToIndex: undefined,
      keywords: '',
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    if (this.props.scrollToFirstElement) {
      const index = this.findFirstRanked();
      if (index) this.scrollToIndex(index);
    }
  }

  componentDidUpdate(prevProps: ApplicationColumnProps, prevState: ApplicationColumnState) {
    const { keywords } = this.state;
    if (this.props.rows !== prevProps.rows) {
      const data = this.filterAndSliceData(keywords);
      this.setState({ data });
    }
    if (this.props.scrollToFirstElement && !this.state.scrolledToFirstElement) {
      const index = this.findFirstRanked();
      if (index) this.scrollToIndex(index);
    }
    if (this.state.data !== prevState.data && this.state.data.length === prevState.data.length) {
      this.listRef?.current?.forceUpdateGrid();
      this.cache.clearAll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.listRef?.current?.forceUpdateGrid();
    this.cache.clearAll();
  };

  filterAndSliceData = (keywords?: string) => {
    const { rows } = this.props;

    let data = rows;

    if (keywords) {
      data = this.filterRows([...rows], keywords);
    }

    return data;
  };

  findFirstRanked = () => {
    let firstRankIndex: number;
    let firstMappedIndex: number;
    firstRankIndex = 0;
    firstMappedIndex = 0;
    this.props.rows.forEach((row, index) => {
      if (!firstMappedIndex && row.mapped) firstMappedIndex = index;
      if (!firstRankIndex && (row.rank === 1 || row.rank === 2)) firstRankIndex = index;
    });

    return firstMappedIndex || firstRankIndex;
  };

  scrollToIndex = (index: number) => {
    this.setState({ scrolledToFirstElement: true, scrollToIndex: index }, () =>
      this.setState({ scrollToIndex: undefined })
    );
  };

  handleDelete = (id: number) => {
    const { deleteCustomValue, subconfigName } = this.props;
    if (deleteCustomValue) deleteCustomValue(subconfigName!, id);
  };

  handleChangeKeywords = (keywords: string) => {
    this.setState({ keywords, data: this.filterAndSliceData(keywords) }, () => this.handleResize());
  };

  handleClick = (row: { id: number }, event: { ctrlKey: any; metaKey: any }, index: number) => {
    const { cmdKeyMultiselect } = this.props;

    if (this.props.mapConfigToApplication) {
      this.props.mapConfigToApplication(this.props.subconfigName!, row.id);
    } else {
      const addValues = cmdKeyMultiselect ? event.ctrlKey || event.metaKey : false;
      if (this.props.submit) this.props.submit(row.id, addValues);
      if (!(addValues && this.props.selected?.includes(row.id))) {
        this.lastSelected = index;
      }
    }
  };

  filterRows = (data: any[], keywords: string) => {
    const keywordsList = utils.stringToArray(keywords.toLowerCase());

    const filteredData = data.filter(element => {
      if (!element.name) return false;
      const name = element.name.toString().toLowerCase();
      let found = true;
      keywordsList.forEach(keyword => {
        if (found) {
          found = name.includes(keyword);
        }
      });
      return found;
    });
    return this.props.subconfigName !== 'positions'
      ? filteredData
      : filteredData.sort((a, b) => {
          if (a.name.length === b.name.length) {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          }
          if (a.name.length < b.name.length) return -1;
          if (a.name.length > b.name.length) return 1;
          return 0;
        });
  };

  multiselectRows = (
    index: number,
    event: { buttons: number; ctrlKey: any; metaKey: any },
    save = false
  ) => {
    let selectedRows = [];
    if (this.selectedStart !== null && this.props.multiselect) {
      if (this.selectedStart !== null && this.selectedStart! < index) {
        selectedRows = this.state.data.slice(this.selectedStart!, index + 1);
      } else if (this.selectedStart !== null && this.selectedStart! > index) {
        selectedRows = this.state.data.slice(index, this.selectedStart! + 1);
      } else {
        selectedRows = [this.state.data[index]];
      }

      selectedRows = selectedRows.map(row => row.id);

      if (event.buttons === 0) save = true;
      if (selectedRows.length > 0) {
        if (this.props.submit)
          this.props.submit(selectedRows, event.ctrlKey || event.metaKey, save);
        this.lastSelected = this.selectedStart;
      }
    }
  };

  shiftMultiSelect = (index: number, event: { ctrlKey: any; metaKey: any }) => {
    const { multiselect } = this.props;
    const { data } = this.state;
    let selectedRows = [];
    if (this.lastSelected !== null && multiselect) {
      if (this.lastSelected < index) {
        selectedRows = data.slice(this.lastSelected, index + 1);
      } else if (this.lastSelected > index) {
        selectedRows = data.slice(index, this.lastSelected + 1);
      } else {
        selectedRows = data[index];
      }
      selectedRows = selectedRows.map((row: { id: any }) => row.id);
      if (this.props.submit) this.props.submit(selectedRows, event.ctrlKey || event.metaKey);
    }
  };

  filter = () => {
    // if props.filter is undefined the rows should be filtered by the frontend
    if (this.props.filter || this.props.filter === undefined) {
      return (
        <div className="application__input">
          <Input.Search
            ref={this.filterInput}
            onChange={e => this.handleChangeKeywords(e.target.value)}
            size="small"
            allowClear
          />
        </div>
      );
    }
  };

  renderRow = ({ index, parent, key, style }: any) => {
    const { data } = this.state;
    let usedByBrand = false;

    const row = data[index];
    if (this.props.divider && row.used_by_brand) usedByBrand = true;

    let cssClasses = row.mapped ? ' mapped' : '';
    let rank = row.rank || 3;
    if (this.props.noRank3 && rank === 3) rank = '';
    if (!this.props.getRecommendations) rank = 0;
    cssClasses += ` rank${rank}`;
    if (usedByBrand && row.used_by_brand === null && [0, 1].includes(rank)) {
      if (!row.mapped) cssClasses += ' divider';
      usedByBrand = false;
    }
    if (rank === 3 && !row.mapped) cssClasses += ' application__row_grey';
    if (row.default) cssClasses += ' application__row_default';

    return (
      <CellMeasurer cache={this.cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        <div
          key={key}
          style={style}
          onClick={e =>
            e.shiftKey ? this.shiftMultiSelect(index, e) : this.handleClick(row, e, index)
          }
          onMouseDown={() => (this.selectedStart = index)}
          onMouseUp={e => {
            if (window.getSelection) window.getSelection()?.removeAllRanges(); // don't select text
            if (this.props.submit && this.selectedStart !== index) this.multiselectRows(index, e);
          }}
          onMouseEnter={e => {
            if (this.props.submit && e.buttons === 1) {
              this.multiselectRows(index, e);
              e.preventDefault();
            }
          }}
          className={`row application__row flex ${cssClasses}`}
          data-testid={row.name}
        >
          <div className="application__row_name flex-1">{row.extended_name || row.name}</div>
          {!!this.props.addCustomValue && row.custom_version_ids?.length > 0 && (
            <DeleteOutlined
              className="self-center pr-1"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                this.handleDelete(row.id);
              }}
            />
          )}
          {row.invalid && <WarningOutlined className="self-center pr-1" />}
        </div>
      </CellMeasurer>
    );
  };

  render() {
    const { addCustomValue, subconfigName, fetching, t } = this.props;
    const { data, scrollToIndex, keywords } = this.state;

    return (
      <div className="float__left application__column">
        <div className="application__title flex justify-between">
          {this.props.title}
          {this.props.handleClearSelection && (
            <Button
              size="small"
              disabled={this.props.selected?.length === 0}
              onClick={() => this.props.handleClearSelection!()}
            >
              {t('common:clearSelection')}
            </Button>
          )}
          {addCustomValue && (
            <div className="flex-1 text-right">
              {subconfigName === 'models' && (
                <VcdbAddModelPopover
                  handleSave={(name, type, group) =>
                    addCustomValue(subconfigName!, name, type, group)
                  }
                  keywords={keywords}
                />
              )}
              {subconfigName === 'vehicle_types' && (
                <VcdbAddTypeConfigPopover
                  handleSave={(name, type, group) =>
                    addCustomValue(subconfigName!, name, type, group)
                  }
                  keywords={keywords}
                />
              )}
              {subconfigName !== 'vehicle_types' && subconfigName !== 'models' && (
                <VcdbAddConfigPopover
                  handleSave={name => addCustomValue(subconfigName!, name)}
                  keywords={keywords}
                />
              )}
            </div>
          )}
        </div>
        {this.filter()}
        <div className="application__table">
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={this.listRef}
                deferredMeasurementCache={this.cache}
                height={height - 2}
                rowCount={data.length}
                rowHeight={this.cache.rowHeight}
                rowRenderer={this.renderRow}
                noRowsRenderer={() => (fetching ? <Spin className="spinner-center" /> : <div />)}
                width={width - 2}
                scrollToIndex={scrollToIndex}
              />
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ApplicationColumn);
