import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { FormikHelpers, FormikProps, FormikValues } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { withContainerWrapper } from '../ContainerWrapper';
import actions from '../../actions/items/application';
import { fetchLinkingItems } from '../../actions/items/application/fetch';
import { updateApplication } from '../../actions/items/application/update';
import resourcesMap from '../../constants/ApplicationResourcesMapping.json';
import { getVehicleResources } from '../../selectors/applications/applicationsSelector';
import { getItemApplicationListFilter } from '../../selectors/catalogue/filterSelector';
import DrawerFormik from '../../components/global/drawer/DrawerFormik';
import { ApplicationState } from '../../reducers';
import { AsyncDispatch } from '../../../types/global';
import { Item } from '../../../types/item';
import {
  Application,
  RankedApplications,
  ApplicationCategory,
  ApplicationDigitalAsset,
  ApplicationType,
} from '../../../types/application';
import { StandardResource, Vehicle } from '../../../types/resources';
import { Brand } from '../../../types/brand';
import { convertConfigIdsNameToName } from '../../utils/ApplicationUtils';
import { intercomEvent } from '../../utils/IntercomUtils';
import IntercomFormEventHandler from '../../components/global/page/IntercomFormEventHandler';
import ApplicationAGTEdit from '../../components/body/application/ApplicationAGTEdit';
import ApplicationOptionsDrawer from '../../components/body/application/ApplicationOptionsDrawer';
import ApplicationVcdbSelect from '../../components/body/application/ApplicationVcdbSelect';
import ApplicationValuePreview from '../../components/body/application/ApplicationValuePreview';
import { ApplicationElement } from '../../../types/all_application_validation';
import { FilterType } from '../../../types/filter';
import { createApplication } from '../../actions/items/application/create';

const resourcesMapping = resourcesMap as { [key: string]: string };

type ApplicationGridEditDrawerProps = {
  dispatch: AsyncDispatch;
  visible: boolean;
  createType?: ApplicationType;
  clone?: boolean;
  selectedItem: Item;
  brandId: number;
  applications: Application[];
  gridRankedApplications: RankedApplications;
  getRecommendations: boolean;
  selectedApplicationId: number | null;
  resources: Vehicle;
  brands: Brand[];
  applicationChildItemIds: number[];
  selectedVcdbs: number[];
  applicationDigitalAssets: any[];
  selectedApplicationDigitalAssetId?: number;
  showApplicationGridView: boolean;
  listFilter: FilterType;
  keywords: string;
  backToOverview?: (refetchApplications?: boolean) => void;
} & WithTranslation;

type ApplicationGridEditDrawerState = {
  optionsDrawerVisisble: boolean;
  selectedConfigKeys: string[];
  vehicleEquipmentType?: ApplicationType;
};

class ApplicationGridEditDrawer extends React.Component<
  ApplicationGridEditDrawerProps,
  ApplicationGridEditDrawerState
> {
  constructor(props: ApplicationGridEditDrawerProps) {
    super(props);
    this.state = {
      optionsDrawerVisisble: false,
      selectedConfigKeys: [],
      vehicleEquipmentType: undefined,
    };
  }

  componentDidMount() {
    const { selectedItem, selectedApplicationId } = this.props;
    if (selectedItem.id) this.props.dispatch(fetchLinkingItems(selectedItem.id));
    if (selectedApplicationId) this.initializeApplication();
  }

  componentDidUpdate(prevProps: ApplicationGridEditDrawerProps) {
    const { selectedApplicationId } = this.props;
    if (selectedApplicationId && prevProps.selectedApplicationId !== selectedApplicationId) {
      this.initializeApplication();
    }
    if (this.props.selectedItem.id && prevProps.selectedItem.id !== this.props.selectedItem.id) {
      this.props.dispatch(fetchLinkingItems(this.props.selectedItem.id));
    }
  }

  initializeApplication = async () => {
    const { selectedApplicationId, applications, showApplicationGridView } = this.props;

    const application = applications.find(a => a.item_application_id === selectedApplicationId);

    const type = Object.keys(application || {}).find(key =>
      ['mfr_ids', 'equipment_model_ids'].includes(key)
    )
      ? ApplicationType.EQUIPMENT
      : ApplicationType.VEHICLE;
    await this.setState({ vehicleEquipmentType: type });

    if (showApplicationGridView && selectedApplicationId)
      this.fetchInitialGridData(selectedApplicationId);
  };

  fetchInitialGridData = (applicationId: number) => {
    const { applications } = this.props;
    const application = applications.find(app => app.item_application_id === applicationId);

    const namedApplication: ApplicationElement = {} as ApplicationElement;
    if (application) {
      Object.keys(application).forEach(key => {
        if (
          (key.includes('_ids') || key === 'years') &&
          !key.startsWith('item_') &&
          key !== 'application_category_ids' &&
          key !== 'vehicle_group_ids' &&
          key !== 'equipment_base_ids'
        ) {
          const configName = convertConfigIdsNameToName(key);
          const allValues = this.allSubconfigValues(configName);
          namedApplication[key] = application[key].map((id: number) =>
            allValues.find((config: StandardResource) => config.id === id)?.name.toString()
          );
        }
      });
    }
    Promise.all([this.getGridRanks(namedApplication)]).then(() => {
      this.props.dispatch(actions.setRecommendations(true));
    });
  };

  setRecommendations = (status: boolean) => this.props.dispatch(actions.setRecommendations(status));

  closeDrawer = (refetchApplications?: boolean) => {
    const {
      applications,
      selectedApplicationId,
      applicationDigitalAssets,
      selectedApplicationDigitalAssetId,
      selectedVcdbs,
      listFilter,
      selectedItem,
      keywords,
    } = this.props;

    this.props.dispatch(actions.selectApplication(null));

    // assets are not handled with the save concept => we need to check for changes on close
    if (!refetchApplications && selectedApplicationDigitalAssetId) {
      const application = applications.find(a => a.item_application_id === selectedApplicationId);
      const applicationAssets = (application?.digital_assets || [])
        .map((a: ApplicationDigitalAsset) => a.file_name)
        .sort();
      const updatedAssets = applicationDigitalAssets.map((a: any) => a.file_name).sort();
      refetchApplications = !isEqual(applicationAssets, updatedAssets);
    }

    if (refetchApplications)
      this.props.dispatch(
        actions.fetchItemApplicationGrids(selectedVcdbs, listFilter.id, selectedItem.id, keywords)
      );

    if (this.props.backToOverview) this.props.backToOverview(refetchApplications);
  };

  handleCategoryUpdate = (
    category: ApplicationCategory | undefined,
    application: Application,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const { getRecommendations } = this.props;
    let updatedApplication;

    if (category) {
      updatedApplication = { ...application, category, category_id: category.id };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { category: x, category_id, ...rest } = application;
      updatedApplication = rest;
    }
    setFieldValue('application', updatedApplication);
    if (getRecommendations && updatedApplication) this.getGridRanks(updatedApplication);
  };

  updateItemApplicationAttributes = (
    param: { [x: string]: any },
    application: Application,
    setFieldValue: FormikHelpers<FormikValues>['setFieldValue']
  ) => {
    const paramName = Object.keys(param)[0];
    const value = param[paramName];

    if (application && application[paramName] !== value) {
      if (value !== undefined) {
        setFieldValue('application', { ...application, [paramName]: value });
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [paramName]: x, ...rest } = application;
        setFieldValue('application', rest);
      }
    }
  };

  getGridRanks = (application: any, vcdbIds?: number[]) => {
    const { createType, selectedVcdbs } = this.props;
    const { vehicleEquipmentType } = this.state;

    const vcdbs = vcdbIds || selectedVcdbs;

    const rankEquipment =
      createType === ApplicationType.EQUIPMENT ||
      vehicleEquipmentType === ApplicationType.EQUIPMENT;

    return this.props.dispatch(actions.fetchGridRanks(application, vcdbs, rankEquipment));
  };

  handleSubmit = async (values: any) => {
    const { selectedItem, clone } = this.props;

    intercomEvent('viewed-all-product', {
      action: 'item-saved',
      location: 'application_edit',
      part_number: selectedItem.part_number,
      brand_code: selectedItem.brand_code,
    });

    if (clone) {
      const newAppl = await this.props.dispatch(
        createApplication(selectedItem.id, selectedItem.category_id)
      );
      await this.props.dispatch(
        updateApplication(
          { ...values.application, item_application_id: newAppl.value.data.id },
          selectedItem.id
        )
      );
    } else {
      await this.props.dispatch(updateApplication(values.application, selectedItem.id));
    }
    this.closeDrawer(true);
  };

  allSubconfigValues = (configName: string) => {
    // get all values to a specific config name e.g. position
    // resourcesMapping contains name mapping e.g. engine_mfrs => mfrs, transmission_mfrs => mfrs
    if (resourcesMapping.hasOwnProperty(configName)) {
      configName = resourcesMapping[configName];
    }

    return this.props.resources ? this.props.resources[configName] : [];
  };

  render() {
    const {
      t,
      selectedApplicationId,
      brandId,
      brands,
      selectedItem,
      applicationChildItemIds,
      applications,
      gridRankedApplications,
      resources,
      selectedVcdbs,
      showApplicationGridView,
      clone,
    } = this.props;
    const { selectedConfigKeys } = this.state;
    const application = applications.find(app => app.item_application_id === selectedApplicationId);

    const brand = brands.find(brand => brand.id === brandId);
    const linkedItemMessage = applicationChildItemIds.length
      ? ` (${t('application:inheritedTo')} ${applicationChildItemIds.length} ${t(
          'glossary:product',
          { count: applicationChildItemIds.length }
        )})`
      : '';

    if (!selectedApplicationId || !application) return null;

    return (
      <DrawerFormik
        className="application__details-drawer"
        title={`${clone ? `${t('application:clone.clone')} ` : ''}${brand?.name}: ${
          selectedItem.part_number || ''
        }${linkedItemMessage}`}
        width="95%"
        visible={this.props.visible}
        onClose={this.closeDrawer}
        destroyOnClose
        enableReinitialize
        initialValues={{
          application,
        }}
        onSubmit={values => this.handleSubmit(values)}
        handleSaveButtonEnabled={(formik: FormikProps<FormikValues>) =>
          selectedConfigKeys.length > 0 && formik.dirty
        }
        push={false}
      >
        {({ setFieldValue, submitForm, values, dirty }) => {
          const getRankedApplications = (vcdbIds?: number[]) =>
            this.getGridRanks(values.application, vcdbIds);

          const updateApplication = (
            updatedApplication: any,
            configName: string,
            filterModel: any
          ) => {
            this.setState({ selectedConfigKeys: Object.keys(updatedApplication) });

            const valApp = values.application;
            const application = {
              ...updatedApplication,
              item_application_id: valApp.item_application_id,
              ...(valApp.hasOwnProperty('item_category_id') && {
                item_category_id: valApp.item_category_id,
              }),
              ...(valApp.hasOwnProperty('item_id') && { item_id: valApp.item_id }),
              ...(valApp.hasOwnProperty('item_mfr_label') && {
                item_mfr_label: valApp.item_mfr_label,
              }),
              ...(valApp.hasOwnProperty('item_position_ids') && {
                item_position_ids: valApp.item_position_ids,
              }),
              ...(valApp.hasOwnProperty('item_qty') && { item_qty: valApp.item_qty }),
              ...(valApp.hasOwnProperty('application_category_ids') && {
                application_category_ids: valApp.application_category_ids,
              }),
              ...(valApp.hasOwnProperty('category') && { category: valApp.category }),
              ...(valApp.hasOwnProperty('category_id') && { category_id: valApp.category_id }),
              ...(valApp.hasOwnProperty('mfr_label') && { mfr_label: valApp.mfr_label }),
              ...(valApp.hasOwnProperty('position_ids') && { position_ids: valApp.position_ids }),
              ...(valApp.hasOwnProperty('notes') && { notes: valApp.notes }),
              ...(valApp.hasOwnProperty('qualifiers') && { qualifiers: valApp.qualifiers }),
              ...(valApp.hasOwnProperty('qualifiers_readable') && {
                qualifiers_readable: valApp.qualifiers_readable,
              }),
              ...(valApp.hasOwnProperty('qty') && { qty: valApp.qty }),
              ...(valApp.hasOwnProperty('part_number') && { part_number: valApp.part_number }),
              ...(valApp.hasOwnProperty('brand_code') && { brand_code: valApp.brand_code }),
              ...(valApp.hasOwnProperty('sub_brand_code') && {
                sub_brand_code: valApp.sub_brand_code,
              }),
              ...(valApp.hasOwnProperty('brand_name') && { brand_name: valApp.brand_name }),
              ...(valApp.hasOwnProperty('sub_brand_name') && {
                sub_brand_name: valApp.sub_brand_name,
              }),
              ...(valApp.hasOwnProperty('sub_brand') && { sub_brand: valApp.sub_brand }),
              ...(valApp.hasOwnProperty('default_overwrite') && {
                default_overwrite: valApp.default_overwrite,
              }),
              ...(valApp.hasOwnProperty('year_from') && { year_from: valApp.year_from }),
              ...(valApp.hasOwnProperty('year_to') && { year_to: valApp.year_to }),
              ...(valApp.hasOwnProperty('vehicle_group_ids') && {
                vehicle_group_ids: valApp.vehicle_group_ids,
              }),
              ...(valApp.hasOwnProperty('vehicle_type_ids') && {
                vehicle_type_ids: valApp.vehicle_type_ids,
              }),
              ...(valApp.hasOwnProperty('equipment_base_ids') && {
                equipment_base_ids: valApp.equipment_base_ids,
              }),
              ...(valApp.hasOwnProperty('production_start') && {
                production_start: valApp.production_start,
              }),
              ...(valApp.hasOwnProperty('production_end') && {
                production_end: valApp.production_end,
              }),
            };

            setFieldValue('application', application);
            if (dirty || !isEqual(application, valApp)) {
              this.getGridRanks(filterModel);
            }
          };

          return (
            <React.Fragment>
              {showApplicationGridView && (
                <div className="flex justify-between mb-2">
                  <ApplicationVcdbSelect
                    onChange={selectedIds => {
                      getRankedApplications(selectedIds);
                    }}
                  />
                  <div className="flex">
                    <div className="application__value-preview mr-3">
                      {(values.application?.category ||
                        values.application?.qty ||
                        values.application?.mfr_label ||
                        values.application?.position_ids) && (
                        <ApplicationValuePreview application={values.application} type="category" />
                      )}
                      {(values.application?.qualifiers || values.application?.notes) && (
                        <ApplicationValuePreview
                          application={values.application}
                          type="qualifiers"
                        />
                      )}
                    </div>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => this.setState({ optionsDrawerVisisble: true })}
                    >
                      {t('application:editApplicationValues')}
                    </Button>
                  </div>
                </div>
              )}
              {this.props.visible && (
                <ApplicationAGTEdit
                  applicationType={this.props.createType || this.state.vehicleEquipmentType}
                  application={values.application}
                  rankedApplications={gridRankedApplications}
                  selectedItemId={selectedItem.id}
                  selectedVcdbs={selectedVcdbs}
                  vehicleResources={resources}
                  getRecommendations={this.props.getRecommendations}
                  setRecommendations={this.setRecommendations}
                  updateApplication={updateApplication}
                />
              )}

              {this.state.optionsDrawerVisisble && (
                <ApplicationOptionsDrawer
                  application={values.application}
                  visible={this.state.optionsDrawerVisisble}
                  handleCategoryUpdate={(category?: ApplicationCategory) =>
                    this.handleCategoryUpdate(category, values.application, setFieldValue)
                  }
                  updateItemApplicationAttributes={(param: { [x: string]: any }) =>
                    this.updateItemApplicationAttributes(param, values.application, setFieldValue)
                  }
                  onClose={() => this.setState({ optionsDrawerVisisble: false })}
                />
              )}

              <IntercomFormEventHandler
                segment="application"
                partNr={selectedItem.part_number}
                brandCode={selectedItem.brand_code}
              />
            </React.Fragment>
          );
        }}
      </DrawerFormik>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: state.user.user,
    applications: state.items.application.applications,
    gridRankedApplications: state.items.application.gridRankedApplications,
    applicationDigitalAssets: state.items.application.applicationDigitalAssets,
    selectedApplicationDigitalAssetId: state.items.application.selectedApplicationDigitalAssetId,
    getRecommendations: state.items.application.getRecommendations,
    applicationChildItemIds: state.items.application.applicationChildItemIds,
    selectedVcdbs: state.items.application.selectedVcdbs,
    showApplicationGridView: state.items.application.showApplicationGridView,
    resources: getVehicleResources(state),
    brandId: state.parent.brands.selectedBrandId,
    brands: state.parent.brands.brands,
    listFilter: getItemApplicationListFilter(state),
  };
}

export { ApplicationGridEditDrawer };
export default connect(mapStateToProps)(
  withContainerWrapper(withTranslation()(ApplicationGridEditDrawer))
);
